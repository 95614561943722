import React from 'react'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../context/UserContext'
import axios from 'axios'
import './header.css'

export default function Header() {
  const { user, setUser } = useContext(UserContext)
  const [res, setRes] = useState()

  useEffect(() => {
    const getResouce = async () => {
      const token = user.token
      const getRes = await axios
        .get('/api/game/res', {
          headers: { 'x-auth-token': token },
        })
        .catch((error) => {
          console.log(error.response)
        })
      setRes(getRes.data)
      console.log(res)
    }

    getResouce()
  }, [user])

  return (
    <div className="Header">
      <div className="Header__inner">Logo</div>
      <div>Титан:</div>
      <div>Кремний:</div>
      <div>Антинатерия:</div>
      <div>Энергия:</div>
      <div>Кредиты:</div>
    </div>
  )
}
