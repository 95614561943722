import React, { useState, useEffect } from 'react'
import UserContext from './context/UserContext'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import './App.css'
import queryString from 'query-string'
import Header from './components/Header'

function App() {
  const [user, setUser] = useState({
    token: undefined,
    user: undefined,
  })

  //const [planet, setPlanet] = useState()

  // vk init
  /*   const VK = window.VK
  VK.init(
    function () {
      console.log('vk инициализировалась')
    },
    function () {
      // API initialization failed
      // Can reload page here

      console.log('vk ошибка')
    },
    '5.126'
  ) */

  /*   function add() {
    VK.api('wall.post', { message: 'Hello!', v: '5.73' }, function (data) {
      alert('Post ID:' + data.response.post_id)
    })
  } */

  useEffect(() => {
    const { auth_key, viewer_id } = queryString.parse(window.location.search)
    const testToken = async (token) => {
      const tokenRes = await axios
        .post('/api/auth/login', null, {
          headers: { 'x-auth-token': token },
        })
        .catch((error) => {
          console.log(error.response)
          localStorage.clear()
          regUser()
        })
      const testRes = tokenRes.data
      return testRes
    }
    const getUser = async (token) => {
      const userRes = await axios
        .get('/api/game/', { headers: { 'x-auth-token': token } })
        .catch((error) => {
          console.log(error)
        })
      const getRes = userRes.data
      setUser({
        token,
        user: getRes,
      })
    }
    const regUser = async () => {
      const token = await axios
        .post('/api/auth', { viewer_id, auth_key })
        .catch((error) => {
          console.log(error.response)
        })
      localStorage.setItem('auth-token', token.data.token)
      getUser(token.data.token)
    }
    const checkLoggedIn = async () => {
      const oldToken = localStorage.getItem('auth-token')
      if (oldToken !== null || undefined || '') {
        if (testToken(oldToken)) {
          getUser(oldToken)
        } else {
          regUser()
        }
      } else {
        regUser()
      }
    }
    checkLoggedIn()
  }, [setUser])

  return (
    <div className="App">
      <UserContext.Provider value={{ user, setUser }}>
        {user.token ? <Header /> : <div>Loading</div>}
        <h3>РАБОТАТЬ!!!!</h3>
        {/* <button onClick={add}>жмякни</button> */}
      </UserContext.Provider>
    </div>
  )
}

export default App

// git add .
// git commit -am "make it better"
// git push heroku master
